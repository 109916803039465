import { createContext } from "react";
import useXProvider from "../hooks/useXProvider";

const XContext = createContext({});

export function XProvider(props) {
  const xValuesProvider = useXProvider();

  return (
    <XContext.Provider value={xValuesProvider}>
      {props.children}
    </XContext.Provider>
  );
}

export default XContext;
