import { useEffect, useState } from "react";
import minion from "../../assets/1.jpeg";
import { heartbeatApi } from "../../utils/heartbeatApi";
import "./App.css";
import useX from "../../hooks/useX";
import useY from "../../hooks/useY";

function App() {
  const [recoveryData, setRecoveryData] = useState([]);
  const { example1 } = useX();
  const { example2 } = useY();

  useEffect(() => {
    heartbeatApi();
    recovery();
  }, []);

  async function recovery() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/energia`, {
        method: "GET",
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data);
      }

      console.log(data);
      setRecoveryData(data);
    } catch (error) {
      console.log(error.message);
    }
  }

  return (
    <div className="App">
      <header className="App-header">
        <h1>Under Mainterance</h1>
        <p className="App-logo">#</p>
        <img src={minion} alt="teste" />
        <div className="content-box">
          {recoveryData.map((item) => {
            return (
              <div className="cardPvw" key={item.id}>
                <p>{item.dia}</p>
                <b>{item.contagem}</b>
                <p>{item.registro}</p>
              </div>
            );
          })}
          <p>
            {new Date().toLocaleDateString() +
              " - " +
              new Date().toLocaleTimeString()}
          </p>
        </div>
      </header>
    </div>
  );
}

export default App;
