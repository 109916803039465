import useYProvider from "../../hooks//useYProvider";
import YContext from "./index";

export function YProvider(props) {
  const yValuesProvider = useYProvider();

  return (
    <YContext.Provider value={yValuesProvider}>
      {props.children}
    </YContext.Provider>
  );
}
