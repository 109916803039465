import { useState } from "react";

export default function useXProvider() {
  const [example1, setExample1] = useState("Hook 1");

  return {
    example1,
    setExample1,
  };
}
