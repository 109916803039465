export async function heartbeatApi() {
  // setInterval(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}`, {
        method: "GET",
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data);
      }

      console.log(data.friendlyMessage);
    } catch (error) {
      console.log(error.message);
    }
  // }, 60000);
}