import { useState } from "react";

export default function useYProvider() {
  const [example2, setExample2] = useState("Hook 2");

  return {
    example2,
    setExample2,
  };
}
