import { XProvider } from "../../context/XContext";
import { YProvider } from "../../context/YContext/YContext";
import "../../styles/global.css";
import Home from "../Home/App";

function Main() {
  return (
    <YProvider>
      <XProvider>
        <Home />
      </XProvider>
    </YProvider>
  );
}

export default Main;
